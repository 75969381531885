import http from '@/shared/http'
import apiResultWrapper from '@/shared/apiResultWrapper'

const API = '/api/v1/system-settings'
const APIM = '/api/v1/reports/mirror'
const APIPOSITIONS = '/api/v1/system-settings/manual-positions'

// fetch information about blotter accounts - in BE is organize structure of users
const fetchEcnFees = async () => apiResultWrapper.apiResult(await http.get(`${API}/ecn-fees`))

// upload new ecn fee file
const uploadEcnFeesFile = async (file) => {
  const formData = new FormData()
  formData.append('file', file)
  return apiResultWrapper.apiResult(await http.post(`${API}/ecn-fees`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  }))
}

// upload new calendar events file
const uploadCalendarEventsFile = async (year, month, file) => {
  const formData = new FormData()
  formData.append('year', year)
  formData.append('month', month)
  formData.append('file', file)
  return apiResultWrapper.apiResult(await http.post(`${API}/events-calendar`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  }))
}

// upload mirror report file
const uploadMirrorReportFile = async (file) => {
  const formData = new FormData()
  formData.append('file', file)
  return apiResultWrapper.apiResult(await http.post(`${APIM}/upload`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  }))
}

// upload manual positions file
const uploadManualPositionsFile = async (file) => {
  const formData = new FormData()
  formData.append('file', file)
  return apiResultWrapper.apiResult(await http.post(`${APIPOSITIONS}/upload`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  }))
}

const downloadEcnFeeFile = async () => {
  return await http.get(`${API}/ecn-fees/download`, { responseType: 'blob' })
}

const downloadManualPositionsFile = async () => {
  return await http.get(`${APIPOSITIONS}/download`, { responseType: 'blob' })
}

const fetchDefaultEcnFees = async () => apiResultWrapper.apiResult(await http.get(`${API}/ecn-fees/default`))

const updateDefaultEcnFees = async (value) => apiResultWrapper.apiResult(await http.post(`${API}/ecn-fees/default`, { value }))

export default {
  fetchEcnFees,
  uploadEcnFeesFile,
  uploadCalendarEventsFile,
  downloadEcnFeeFile,
  fetchDefaultEcnFees,
  updateDefaultEcnFees,
  uploadMirrorReportFile,
  uploadManualPositionsFile,
  downloadManualPositionsFile
}
