<template>
  <v-container
    id="system-settings"
    fluid
    tag="section"
  >

    <v-row>
      <v-col class="12">
        <v-tabs
          v-model="tab"
          fixed-tabs
        >
          <v-tab :disabled="isLoading">
            <span class="tab-header">Calendar Events</span>
          </v-tab>
          <v-tab :disabled="isLoading">
            <span class="tab-header">ECN Fees</span>
          </v-tab>
          <v-tab :disabled="isLoading">
            <span class="tab-header">Mirror Report</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
          class="pb-1"
        >
          <v-tab-item>
            <v-row class="ml-5 mr-5 mt-5">
              <v-col cols="12">
                <p><b>Important!!!</b> Follow the rules for file format</p>
                <ul>
                  <li>The file format should be in *.csv</li>
                  <li>Column separator should be ';'</li>
                  <li>First row should be a headers no matter names</li>
                  <li>Columns order is 'date', 'time', 'event/comment'</li>
                  <li>Column date format should be 'yyyy-MM-dd' -> '2022-07-13'</li>
                  <li>Column time format should be 'HH:mm:ss' -> '23:12:00'</li>
                  <li>In comment should not have symbol ';'</li>
                  <li>
                    Example file:
                    <i>
                    <pre>

Date;Time;Comment;
2022-07-13;21:02:23;Interest
2022-07-13;22:12:23;CPI

                    </pre>
                    </i>
                  </li>
                </ul>
              </v-col>
            </v-row>
            <v-row class="ml-5 mr-5">
              <v-col cols="8">
                <v-file-input
                  v-model="calendarEventsFile"
                  accept=".csv"
                  label="Click to attach file *"
                  :disabled="isLoading"
                  counter
                  show-size
                  clearable
                  class="pt-0"
                />
              </v-col>
              <v-col cols="2">
                <v-dialog
                  ref="to"
                  v-model="calendarEventsDateModel"
                  :return-value.sync="calendarEventsDate"
                  :disabled="isLoading"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="calendarEventsDate"
                      class="mt-0 pt-0"
                      :disabled="isLoading"
                      label="For date *"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="calendarEventsDate"
                    :max="maxDay"
                    type="month"
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="calendarEventsDateModel = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.to.save(calendarEventsDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="2"
                class="text-right"
              >
                <v-btn
                  color="primary"
                  :disabled="isLoading || !calendarEventsFile || !calendarEventsDate"
                  @click="uploadCalendarEventsFile(calendarEventsFile)"
                >
                  Upload Calendar Events
                </v-btn>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row class="ma-5">
              <v-col cols="8">
                <v-text-field
                  v-model="defaultEcnValue"
                  label="Default ECN Value"
                  :disabled="isLoading"
                  counterdsad
                  type="number"
                  clearable
                  class="pt-0"
                />
              </v-col>
              <v-col cols="4" class="text-right">
                <v-btn
                  color="secondary"
                  :disabled="isLoading"
                  @click="updateDefaultEcn()"
                >
                  UPDATE DEFAULT ECN
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="ma-5">
              <v-col cols="8">
                <v-file-input
                  v-model="file"
                  accept=".csv"
                  label="Click to attach file *"
                  :disabled="isLoading"
                  counter
                  show-size
                  clearable
                  class="pt-0"
                />
              </v-col>
              <v-col
                cols="2"
                class="text-right"
              >
                <v-btn
                  color="primary"
                  :disabled="isLoading || !file"
                  @click="uploadNewEcnFeesFile(file)"
                >
                  Upload Ecn Fees
                </v-btn>
              </v-col>
              <v-col
                cols="2"
                class="text-right"
              >
                <v-btn
                  color="secondary"
                  :disabled="isLoading"
                  @click="downloadEcnFeesFile()"
                >
                  Download Ecn Fees File
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="ecnFeesHeaders"
                  :items="ecnFees"
                  :items-per-page="1000"
                  :loading="isLoading"
                  :disable="isLoading"
                  hide-default-footer
                  :disable-sort="false"
                  loading-text="Loading... Please wait"
                  class="elevation-1 row-pointer"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>

            <v-row class="ml-5 mr-5 mt-5">
              <v-col cols="12">
                <p><b>How to update mirror report accounts</b></p>
                <p><b> </b></p>
                <ul>
                  <li>Upload an excel file having a sheet named 'Accounts'.</li>
                  <li>The sheet 'Accounts' must have all accounts to be processed by mirror report.</li>
                  <li>The sheet also must have the following columns in this order: Manager, Account Eze id, Shared. The names of the
                  columns is irrelevant.</li>
                </ul>

                <p><b> </b></p>
                <p><b>How to update mirror balance.</b></p>
                <ul>
                    <li>Upload an excel file having a sheet named 'Balance'.</li>
                    <li>Changes will take place in the next mirror report. Since mirror reports generation is two days behind updating
                    balance on 25th will be used in reports starting from 24th.
                    <li>To update manager's balance insert a row with following columns: Manager's Eze Id, Date, Description, Debit,
                        Credit, Balance. The names of the columns is irrelevant.</li>
                    <li>The value from column 'Balance' is used in the mirror report and it must be filled correctly. </li>
                    <li>When using mirror report file any existing rows are ignored during the update.</li>
                </ul>
              </v-col>
            </v-row>
             <v-row class="ma-5">
                          <v-col cols="8">
                            <p>The accepted file formats are .xlsx and .xls. The actual mirror report can be used.</p>
                            <v-file-input
                              v-model="file"
                              accept=".xlsx"
                              label="Click to attach file *"
                              :disabled="isLoading"
                              counter
                              show-size
                              clearable
                              class="pt-0"
                            />
                          </v-col>

                          <v-col
                            cols="2"
                            class="text-right"
                          >
                            <v-btn
                              color="primary"
                              :disabled="isLoading || !file"
                              @click="uploadNewMirrorReportFile(file)"
                            >
                              Upload Mirror Report
                            </v-btn>
                          </v-col>

                        </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row>
      <div class="text-center">
        <v-dialog
          v-model="dialog.show"
          width="500"
        >
          <v-card>
            <v-card-title class="headline lighten-2">
              <span
                :class="`${dialog.error ? 'red--text' : 'blue--text'} text-uppercase`"
                style="letter-spacing: 0.3rem"
              > {{ dialog.title }} </span>
            </v-card-title>

            <v-card-text
              align="center"
            >
              {{ dialog.message }}
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="dialog.show = false"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import SystemSettingService from './SystemSettingService'

export default {
  name: 'SystemSettings',
  data: () => ({
    tab: 0,
    defaultEcnValue: '',
    file: null,
    calendarEventsFile: null,
    calendarEventsDateModel: false,
    calendarEventsDate: '',
    isLoading: false,
    ecnFees: [],
    ecnFeesHeaders: [
      { text: 'Route', value: 'route' },
      { text: 'Liquidity Indicator', value: 'liqIndicator' },
      { text: 'Value', value: 'value' }
    ],
    dialog: {
      show: false,
      title: '',
      message: ''
    }
  }),
  computed: {
    maxDay: () => {
      const now = new Date()
      let current
      if (now.getMonth() === 10) {
        current = new Date(now.getFullYear() + 1, 0, 1)
      } else if (now.getMonth() === 11) {
        current = new Date(now.getFullYear() + 1, 1, 1)
      } else {
        current = new Date(now.getFullYear(), now.getMonth() + 2, 1)
      }
      return current.toISOString().substr(0, 10)
    }
  },
  beforeMount () {
    this.fetchEcnFees()
  },
  methods: {
    async fetchEcnFees () {
      this.isLoading = true
      const responseDefaultEcn = await SystemSettingService.fetchDefaultEcnFees()
      const response = await SystemSettingService.fetchEcnFees()
      if (response.status === 200 && responseDefaultEcn.status === 200) {
        this.ecnFees = response.data
        this.defaultEcnValue = responseDefaultEcn.data.value
      } else {
        this.dialog.show = true
        this.dialog.title = 'Error'
        this.dialog.error = true
        this.dialog.message = 'Cannot fetch ECN Fees!'
      }
      this.isLoading = false
    },
    async saveEcnFeesFile (file) {
      this.isLoading = true
      const response = await SystemSettingService.uploadEcnFeesFile(file)
      if (response.status === 200) {
        this.dialog.show = true
        this.dialog.title = 'Success'
        this.dialog.error = false
        this.dialog.message = 'Successfully save new ENC Fees!'
      } else {
        this.dialog.show = true
        this.dialog.title = 'Error'
        this.dialog.error = true
        this.dialog.message = 'Cannot save ECN Fees file!'
      }
      this.file = null
      this.isLoading = false
    },
    async saveMirrorReportFile (file) {
          this.isLoading = true
          const response = await SystemSettingService.uploadMirrorReportFile(file)
          if (response.status === 200) {
            this.dialog.show = true
            this.dialog.title = 'Success'
            this.dialog.error = false
            this.dialog.message = 'Successfully uploaded Mirror report file!'
          } else {
            this.dialog.show = true
            this.dialog.title = 'Error'
            this.dialog.error = true
            this.dialog.message = 'Cannot upload Mirror report file!'
          }
          this.file = null
          this.isLoading = false
        },
    async downloadEcnFeesFile () {
      this.isLoading = true
      const response = await SystemSettingService.downloadEcnFeeFile()
      if (response.status === 200) {
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'ecn-fees.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } else {
        this.dialog.show = true
        this.dialog.title = 'Error'
        this.dialog.error = true
        this.dialog.message = 'Cannot download ECN Fees file!'
      }
      this.isLoading = false
    },
    async uploadNewEcnFeesFile (file) {
      await this.saveEcnFeesFile(file)
      await this.fetchEcnFees()
    },
    async uploadNewMirrorReportFile (file) {
      await this.saveMirrorReportFile(file)
    },
    async uploadCalendarEventsFile (file) {
      this.isLoading = true
      const d = new Date(this.calendarEventsDate)
      const year = d.getFullYear()
      const month = d.getMonth() + 1
      const response = await SystemSettingService.uploadCalendarEventsFile(year, month, file)
      if (response.status === 200) {
        this.dialog.show = true
        this.dialog.title = 'Success'
        this.dialog.error = false
        this.dialog.message = 'Successfully save new Calendar Events!'
        this.calendarEventsFile = null
      } else {
        this.dialog.show = true
        this.dialog.title = 'Error'
        this.dialog.error = true
        this.dialog.message = 'Cannot save Calendar Events!'
      }
      this.isLoading = false
    },
    async updateDefaultEcn () {
      this.isLoading = true
      const response = await SystemSettingService.updateDefaultEcnFees(this.defaultEcnValue)
      if (response.status === 200) {
        this.dialog.show = true
        this.dialog.title = 'Success'
        this.dialog.error = false
        this.dialog.message = 'Successfully save new default ENC Fee!'
      } else {
        this.dialog.show = true
        this.dialog.title = 'Error'
        this.dialog.error = true
        this.dialog.message = 'Cannot save default ENC Fee!'
      }
      this.file = null
      this.isLoading = false
    }
  }
}
</script>
<style scoped>
.tab-header {
  font-weight: 400;
  letter-spacing: 0.3rem;
}
</style>
